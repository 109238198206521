import React from "react"
import Exhibition from "./exhibition"

const ExhibitionsList = ({exhibitions}) => {
    let index=0;
    return (
        <div>
            <p></p>
        <ul>
            {exhibitions.sort((a, b) => b.year - a.year).map((exhibitionData) => {
                return <Exhibition exhibition={exhibitionData} key={index++} />
            })}
        </ul>
        </div>
    )
}
export default ExhibitionsList