import React from "react"

const Exhibition = ({exhibition}, key) => {
    let description = `${exhibition.year} 
     ${exhibition.title ? `${exhibition.title}, ` : ''}
     ${exhibition.gallery ? `${exhibition.gallery}, ` : ''}    
     ${exhibition.museum ? `${exhibition.museum}, ` : ''} 
     ${exhibition.location} (${exhibition.country})`;
    return <li className="small" key={key}>{description}</li>
}
export default Exhibition