import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import JSONData from "../data/exhibitions.json"
import ExhibitionsList from "../components/exhibitionsList"
import { Tab, Row, Nav, Col } from "react-bootstrap"

const TabbedExhibitions = (props) => {

    return (
        <Layout>
            <Seo title="Exhibitions" />
            <h2>Exhibitions</h2>

            <Tab.Container id="exhibitions-list-pills" defaultActiveKey="solo">
                <Row>
                    <Col>
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="solo" href="#">Solo Exhibitions</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="group" href="#">Group Exhibitions</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Tab.Content>
                        <Tab.Pane eventKey="solo">
                            <ExhibitionsList exhibitions={JSONData.filter(exhibition => exhibition.type === 'solo')} title="Solo Exhibitions" />
                        </Tab.Pane>
                        <Tab.Pane eventKey="group">
                            <ExhibitionsList exhibitions={JSONData.filter(exhibition => exhibition.type === 'group')} title="Group Exhibitions" />
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

            {/* <Tabs defaultActiveKey="solo" id="exhibition-tabs" className="mb-2">
                <Tab eventKey="solo" title="Solo Exhibitions">
                    <ExhibitionsList exhibitions={JSONData.filter(exhibition => exhibition.type === 'solo')} title="Solo Exhibitions" />
                </Tab>
                <Tab eventKey="group" title="Group Exhibitions">
                    <ExhibitionsList exhibitions={JSONData.filter(exhibition => exhibition.type === 'group')} title="Group Exhibitions" />
                </Tab>
            </Tabs> */}

        </Layout>
    )
}

export default TabbedExhibitions